
import axios from 'axios';
// actions
export const actions = {

    async index(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/monitoring-jabatan-struktural?page=' + payload.page + '&nama_unit_kerja=' + payload.data.nama_unit_kerja + '&jabatan=' + payload.data.jabatan)
            return data
        } catch (error) {
            return []
        }
    },

    async existing(_ctx, payload) {
        try {
            if(payload.nip != null){
                const { data } = await axios.get('/api/monitoring-jabatan-struktural/existing-employee?nip=' + payload.nip)
                return data
            }else{
                return []
            }
        } catch (error) {
            return []
        }
    },

    async kandidat(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/monitoring-jabatan-struktural/kandidat?id_unit_kerja=' + payload.item.id_unit_kerja + '&id_eselon=' + payload.item.id_eselon + '&nip=' + payload.item.nip + '&id_tingkat_pendidikan=' + payload.data.id_tingkat_pendidikan + '&nama_pegawai=' + payload.data.nama_pegawai)
            return data
        } catch (error) {
            return []
        }
    },

    async autocomplete_nama_pegawai(_ctx, payload) {
        try {
            const { data } = await axios.get('/api/monitoring-jabatan-struktural/autocomplete_nama_pegawai?search=' + payload.search + '&id_unit_kerja=' + payload.item.id_unit_kerja + '&id_eselon=' + payload.item.id_eselon + '&nip=' + payload.item.nip)
            return data
        } catch (error) {
            return []
        }
    },
}
